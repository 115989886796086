.nk-slider{
    position: relative;
    &-s1{
        width: 290px;
        max-width: 100%;
        .slick-dots{
            position: absolute;
            right: 1.25rem;
            top: 1.5rem;
            padding-top: 0;
        }
    }
    &-s2{
        .slider-arrows{
            > div{
                padding: .5rem 0;
            }
        }
    }
}