.team{
    position: relative;
    &-info{
        padding: 1rem 0 1.25rem;
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: .9375rem;
            line-height: 1.75rem;
            span {
                &:first-child{
                    color: $base-light;
                }
                &:last-child{
                    color: $base-text;
                }
            }
        }
    }
    &-status{
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }
    &-options{
        position: absolute;
        top: -.25rem;
        right: -.25rem;
    }
    &-details{
        padding-top: .5rem;
        text-align: center;
        max-width: 200px;
        margin: 0 auto;
    }
    &-statistics{
        display: flex;
        justify-content: space-around;
        text-align: center;
        padding: 1rem 0 1.5rem;
        li{
            span {
                display: block;
                &:first-child{
                    font-size: 1.125rem;
                    color: $base-dark;
                }
                &:last-child{
                    font-size: .875rem;
                    color: $base-light;
                }
            }
        }
    }
    &-view{
        display: flex;
        justify-content: center;
        padding-bottom: 0.25rem;
    }
}