.asterisk{
    a{
        position: relative;
        color: $base-light;
        display: inline-flex;
        &:hover,&.active{
            color: $orange;
            .asterisk{
                &-on{
                    opacity: 1;
                }
            }
        }
        &.active:hover{
            .asterisk{
                &-on{
                    opacity: 0;
                }
            }
        }
    }
    &-on{
        opacity: 0;
        position: absolute;
        left: 0;
        color: $orange;
        z-index: 1;
        transition: opacity .2s;
    }
}