.attach{
    &-files{
        margin-top: 1.75rem;
        border:1px solid $border-light;
        border-radius: $border-radius;
    }
    &-list{
        padding: 1rem 1.25rem;
        margin-bottom: -.5rem;
        display: flex;
        flex-wrap: wrap;
    }
    &-item{
        width: 180px;
        padding-right: 2rem;
        padding-bottom: 0.5rem;
        a{
            display: flex;
            align-items: center;
            width: 100%;
            color: $base-text;
        }
        .icon{
            font-size: 1.5rem;
            color: $accent-color;
            margin-right: 0.75rem;
        }
        span{
            @extend .ellipsis;
        }
    }
    &-foot{
        border-top:1px solid $border-light;
        background: $lighter;
        padding: .75rem 1.25rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &-info{
        font-weight: $fw-medium;
        color: $base-color;
    }
    &-download{
        span{
            color: $base-light;
            transition: all .3s;
        }
        &:hover{
            span{
                color: $accent-color;
            }
        }
    }
}

@media (max-width:359px){
    .attach{
        &-info{
            font-size: $fx-sz-12;
        }
        &-download{
            font-size:  $fx-sz-12;
        }
    }
}