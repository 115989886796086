canvas{
    width: 100%;
    max-width: 100%;
}
a:hover{
    text-decoration: none;
}
p:last-child{
    margin-bottom: 0;
}
.del {
	text-decoration: line-through;
}
span[data-toggle=tooltip]:focus {
    outline: none;
}