$list-default-icon: $ni-bullet-fill !default;
$list-checked-icon: $ni-check-fill-c !default;
$list-checked-circle-icon: $ni-check-circle-fill !default;
$list-cross-icon: $ni-cross-fill-c !default;
$list-step-icon: $ni-circle !default;
$list-step-current-icon: $ni-disk !default;
$list-step-done-icon: $ni-check-circle-cut !default;

.list{
    &:not(:last-child) {
        margin-bottom: $para-margin;
    }
    li{
        position: relative;
        padding-left:1.5rem;
        line-height: 1.5rem;
        &:not(:last-child){
            padding-bottom: .5rem;
        }
        &:before{
            position: absolute;
            left: 0;
            font-size: $fx-sz-14;
            line-height: 1.5rem;
            font-family: $nk-dashlite-font;
            content: $list-default-icon;
        }
        span{
            color: $base-light;
        }
        ul {
            margin-top: .5rem;
        }
    }
    &-checked{
        li:before{
            color: $accent-color;
            content: $list-checked-icon;
        }
        &-circle{
            li:before{
                color: $accent-color;
                content: $list-checked-circle-icon;
            }
        }
    }
    &-cross {
        li:before{
            color: rgba($danger, .8);
            content: $list-cross-icon;
        }
    }
    &-status {
        list-style: none;
        line-height: 1.3;
        li {
            display: inline-flex;
            align-items: center;
            padding-right: 1rem;
            vertical-align: middle;
        }
        .icon {
            margin-right: .375rem;
            line-height: 1.3;
        }
    }
    &-lg {
        li {
            padding-left: 2rem;
            &:not(:last-child){
                padding-bottom: .75rem;
            }
            &:before{
                font-size: 1.35rem;
            }
        }
    }
    &-sm{
        li{
            padding-left: 1.2rem;
            &:not(:last-child){
                padding-bottom: .35rem;
            }
            &:before{
                font-size: $fx-sz-12;
            }
        }
    }
    &-step{
        li{
            color: $base-light;
            font-weight: 500;
            &:before{
                content: $list-step-icon;
            }
            &.list-step{
                &-current{
                    color: $base-color;
                    &:before{
                        content: $list-step-current-icon;
                        color: $accent-color;
                    }
                }
                &-done{
                    color: $base-text;
                    &:before{
                        content: $list-step-done-icon;
                        color: $success;
                    }
                }
            }
        }
    }
    &-plain {
        a {
            color: $base-text;
            display: block;
            line-height: 1.3;
            padding: 0.5rem 0;
            &:hover {
                color: $accent-color;
            }
        }
    }
    &-category {
        a {
            font-weight: $fw-medium;
        }
    }
    &-download {
        li > a {
            display: inline-block;
            padding: .25rem;
            > .icon {
                margin:0 .25rem;
                font-size: 1.25rem;
            }
        }
    }
}


@each $name, $value in $theme-colors {
    .list-#{$name} {
        li:before{
            color: $value;
        }
    }
}