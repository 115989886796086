h1, .h1, h2, .h2 {letter-spacing: -0.03em;}
h3, .h3, h4, .h4 {letter-spacing: -0.02em;}
h5, .h5, h6, .h6 {letter-spacing: -0.01em;}

@include media-breakpoint-up(lg){
    h1, .h1 { @include font-size($h1-fs-lg); font-weight: $fw-normal; letter-spacing: -0.04em;}
    h2, .h2 { @include font-size($h2-fs-lg); letter-spacing: -0.03em;}
    h3, .h3 { @include font-size($h3-fs-lg); letter-spacing: -0.03em;}
    h4, .h4 { @include font-size($h4-fs-lg); }
    h5, .h5 { @include font-size($h5-fs-lg); }
    h6, .h6 { @include font-size($h6-fs-lg); }
}
.list-inline{
    li{
        display: inline-block;
        &:not(:last-child){
            margin-right: 1rem;
        }
    }
}
.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}
.text-ellipsis{
    @extend .ellipsis;
}
.ccap{
    @extend .text-capitalize;
}
.lcap{
    @extend .text-lowercase;
}
.ucap{
    @extend .text-uppercase;
}
.ff {
    &-mono {
        @extend .text-monospace
    }
    &-base {
        font-family: $base-font-family !important;
    }
    &-alt {
        font-family: $alt-font-family !important;
    }
    &-italic {
        @extend .font-italic;
    }
}
.fw{
    &-light{
        @extend .font-weight-light;
        &er{
            @extend .font-weight-lighter;
        }
    }
    &-normal{
        @extend .font-weight-normal;
    }
    &-medium{
        font-weight: $fw-medium !important;
    }
    &-bold{
        @extend .font-weight-bold;
        &er{
            @extend .font-weight-bolder;
        }
    }
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  .text-white &{
    color: $white;
  }
}
@if($lead-fs < $lead-fs-dsk) {
    @include media-breakpoint-up(lg){
        .lead{
            font-size: $lead-fs-dsk;
        }
    }
}
